.attributes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 425px) {
        flex-direction: column;
        align-items: start;
        gap: 7px;
    }
}