.balance-sheet-container{
    background-color: #21252912;
    padding: 1rem 2rem;
    border-radius: .5rem;
    &__heading{
        text-align: center;
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 700;
    }
}

.sheet-heading{
    padding: 1rem;
    width: 100%;
    background-color: #2f3c4e;
    color: white;
    font-weight: 600;
}
.sheet-data{
    padding: .5rem 1rem;
    border-bottom: 1px solid #2f3c4e;
    background-color: white;
    width: 100%;
    color: #2f3c4e;
}
.sheet-data1{
    padding: .5rem 1rem;
    background-color: white;
    width: 100%;
    color: #2f3c4e;
}
.sheet-result{
    padding: .5rem 1rem;
    width: 100%;
    background-color: white;
}