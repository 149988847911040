/**=====================
    82. avatars CSS start
==========================**/
.avatars{
    .avatar{
        display: inline-block;
        margin-right: 10px;
        position: relative;
        &:last-child{
            margin-right: 0;
        }

        $i:15;
        @while $i<=35 {
            .b-r-#{$i} {
                border-radius:#{$i}px;
            }
            $i:$i+5;
        }
        $j:50;
        @while $j<=100 {
            .height-#{$j} {
                height:#{$j}px;
            }
            $j:$j+10;
        }
        .status-100 {
            width: 25px;
            height: 25px;
        }
        .status-90 {
            width: 20px;
            height: 20px;
        }
        .status-80 {
            width: 15px;
            height: 15px;
        }
        .status-70 {
            width: 12px;
            height: 12px;
        }
        .status-60 {
            width: 10px;
            height: 10px;
        }
        .status-50 {
            width: 9px;
            height: 9px;
        }
        .status-40 {
            width: 8px;
            height: 8px;
        }
        .status-30 {
            width: 7px;
            height: 7px;
        }
        &.ratio1{
            img{
                width: auto;
            }
        }
        .status{
            position: absolute;
            background-color: $light-text;
            bottom: 0;
            right: 4px;
            border-radius: 100%;
            border: 1px solid $white;
        }

    }
}
.customers{
    ul{
        li{
            img{
                border: 2px solid $white;
                box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
                transition: 0.5s;
                &:hover{
                    transform: translateY(-4px) scale(1.02);
                    transition: 0.5s;
                }
            }
            + li{
                margin-left: -16px;
            }
            &:nth-child(4){
                margin-left: 1px;
            }
        }
    }
    &.avatar-group{
       margin-right: 30px;
        &:last-child{
            margin-right: 0;
        }
    }
}
.avatar-showcase{
    margin-bottom: -10px;
    .avatars{
        .avatar{
            margin-bottom: 10px;
        }
    }
    .avatar-group{
        margin-bottom: 10px;
    }
}
/**=====================
     82. avatars CSS Ends
==========================**/
