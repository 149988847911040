.paginationInput::-webkit-inner-spin-button,
.paginationInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.paginationInput {
  -moz-appearance: textfield;
}

.paginationInput {
  max-width: 90px;
  padding: .3rem !important;
  border: 1px solid #2f3c4e !important;
}
.paginationInput:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #2f3c4e !important;
}

.pagination-item>a {
  color: #2f3c4e !important;
  &:hover{
    background-color: #2f3c4e !important;
    color: white !important;
  }
}
.page-link {
  color: #2f3c4e !important;
  &:hover{
    background-color: #2f3c4e !important;
    color: white !important;
  }
}

.pagination-active>a {
  background-color: #2f3c4e !important;
  color: white !important;
}

.btn-custom-pagination{
  border: 1px solid #2f3c4e !important;
  &:hover{
    background-color: #2f3c4e !important;
    color: white !important;
  }
}