.outer-table {
    border-collapse: collapse;
    width: 100%;
  }

  .outer-table td {
    padding: 5px 10px;
    border: 1px solid #ccc;
  }

  .inner-table {
    border-collapse: collapse;
    width: 100%;
  }

  .inner-table td {
    padding: 3px 5px;
    border: 1px solid #ccc;
  }

  .inner-table-bg{
    background-color: rgba(176, 176, 176, 0.075) !important;
  }

  .label-product-breakdown{
    font-size: medium;
    font-weight: 700;
  }