.dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}

.linkEdit {
  padding: 6px;
  cursor: pointer;
  display: block;
}
.linkEdit:hover {
  background-color: #e8e8e8;
}
