.error-message {
    position: absolute;
    color: red;
    font-size: 12px;
}

.inputError {
    border: 1px solid red !important;
    box-shadow: none !important;
    outline: none !important;
}

.inputclass {
    border: 1px solid #31313182 !important;
    color: #212529 !important;
}

.inputclass:focus {
    outline: none !important;
    box-shadow: none !important;
}

.textareaclass {
    border: 1px solid #31313182 !important;
}

.textareaclass:focus {
    border: 1px solid #31313182 !important;
    outline: none !important;
    box-shadow: none !important;
}

.input_wrapper {
    position: relative;
}

.password-toggle {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: 2%;
    cursor: pointer;
}