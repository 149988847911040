.css-13cymwt-control {
    border-radius: 6px !important;
    border: none !important;
    max-height: 34px !important;
    min-height: 34px !important;
    outline: none !important;
    &:focus {
        outline: none !important;
    }
}

.zindex-top{
    z-index: 11;
}