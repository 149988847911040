/**=====================
     87. typahead-search  CSS Start
==========================**/
.Typeahead {
  width: 100%;
  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &:before {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    &:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
  .tt-hint {
    display: none !important;
  }
}
.u-cf {
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}
.u-hidden {
  display: none !important;
}
.u-posRelative {
  position: relative;
}
.Typeahead-spinner {
    position: absolute;
    top: 14px;
    right: 18px;
    display: none;
    color: #c5c5c5;
}
.ProfileCard-avatar{
  svg{
    color: #7e7c7c !important;
    path, polyline, polygon, line {
      color: #7e7c7c!important;
    }
  }
}

.Typeahead-hint {
  width: 100%;
  padding: 5px 8px;
  font-size: 24px;
  line-height: 30px;
  border: 1px solid #024e6a;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  color: #ccd6dd;
  opacity: 1;
}

.Typeahead-menu {
  position: absolute;
  max-height: 300px;
  top: 120%;
  padding: 12px;
  z-index: 100;
  display: none;
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 0px 0px 1px green;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
  -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
}
.Typeahead-menu.is-open {
  display: block;
}
.Typeahead-selectable {
  cursor: pointer;
  + {
    .Typeahead-selectable {
      border-top: 1px solid #ccd6dd;
    }
  }
}
.ProfileCard {
  position: relative;
  padding: 8px;
  border: none!important;
  &:hover {
    .ProfileCard-avatar {
      border-color: #f5f8fa;
    }
    color: #414345;
    background: #f8f8f8;
    .ProfileCard-screenName {
      color: #fff;
    }
    .ProfileCard-stat-label {
      color: #fff;
    }
  }
}
.ProfileCard-avatar {
  position: absolute;
  border-radius: 5px;
}
.ProfileCard-details {
  padding-left: 30px;
  float: left;
}
.ProfileCard-realName {
  display: inline-block;
  font-weight: 500;
   a, span {
     color: $theme-body-font-color;
   }
}
.ProfileCard-screenName {
  display: inline-block;
  color: #8899a6;
}
.ProfileCard-description {
  margin-top: 5px;
  font-size: 14px;
  line-height: 18px;
}
.ProfileCard-stats {
  float: right;
  text-align: right;
}
.ProfileCard-stat {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  + {
    .ProfileCard-stat {
      margin-left: 5px;
    }
  }
}
.ProfileCard-stat-label {
  color: #8899a6;
  font-weight: 500;
}
.ProfileCard.is-active {
  color: #fff;
  background: #55acee;
  .ProfileCard-screenName {
    color: #fff;
  }
  .ProfileCard-stat-label {
    color: #fff;
  }
}
.EmptyMessage {
  position: relative;
  padding: 10px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}
.Typeahead-spinner {
  width: 1rem;
  height: 1rem;
}
/**=====================
     87. typahead-search CSS Ends
==========================**/