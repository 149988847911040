.relative-drop {
  position: relative;
}
.absolute-drop {
  position: absolute;
  z-index: 99999 !important;
}

.truncate-line-custom {
  max-width: 170px;
  word-wrap: break-word;
}

.show-congfigrations_customers {
  height: 215px !important;
  opacity: 1 !important;
  visibility: visible !important;
  @media (max-width: 767.98px) {
    height: 310px !important;
  }
  @media (max-width: 575.98px) {
    height: 460px !important;
  }
}

// .show-congfigrations_Cusomer {
//   height: 315px !important;
//   opacity: 1 !important;
//   visibility: visible !important;

//   @media (max-width: 767.98px) {
//     height: 400px !important;
//   }

//   @media (max-width: 575.98px) {
//     height: 600px !important;
//   }
// }
