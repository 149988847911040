.show-congfigrationsForm {
  height: 460px !important;
  opacity: 1 !important;
  visibility: visible !important;

  @media (max-width: 767.98px) {
    height: 195px !important;
  }

  @media (max-width: 575.98px) {
    height: 330px !important;
  }
}

.show-congfigrationsFormSmall {
  height: 160px !important;
  opacity: 1 !important;
  visibility: visible !important;

  @media (max-width: 767.98px) {
    height: 195px !important;
  }

  @media (max-width: 575.98px) {
    height: 330px !important;
  }
}

// .custom-markdown-editor {
//   height: 100px !important;
// }

// .custom-quill-editor {
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   padding: 10px;
//   height: 300px;
// }

// .editor_container_SimpleMDE {
//   height: 10px !important;
//   width: 4000px !important;
// }

// .custom-ckeditor {
//   font-family: Arial, sans-serif;
//   font-size: 16px;
//   color: #333;
//   height: 10px !important;
// }

// .custom-ckeditor h1 {
//   font-size: 24px;
//   font-weight: bold;
//   color: #ff6600;
// }

// .custom-ckeditor ul {
//   list-style: square;
//   margin-left: 20px;
// }

// .custom-ckeditor ol {
//   list-style: decimal;
//   margin-left: 20px;
// }
