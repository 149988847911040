$header-size: 80px;

/**=====================
    05. Header CSS Start
==========================**/
%common {
  color: $white;
  border-left-color: $white;
  transition: $sidebar-transition;
}
%for-animated-hover {
  box-shadow: 0 0 2px 2px $light-color;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  &:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }
  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $dark-gray;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}
%sidebar-icon {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: $dark-card-background;
  color: $dark-gray;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}
%header-unvisible {
  position: absolute;
  content: "";
  background-color: #ebebeb;
  left: 0;
  margin: 0 auto;
  right: 0;
  z-index: 2;
  animation: shine-lines 2s infinite ease-out;
  background-size: cover;
  background-image: linear-gradient(
    90deg,
    rgba(246, 246, 246, 0.6) 0px,
    #ebebeb 40px,
    rgba(246, 246, 246, 0.6) 80px
  );
}
/*======= Page Header css Start ======= */
.compactLogo {
  a {
    img {
      &:first-child {
        display: none;
      }
    }
  }
  &.show {
    a {
      img {
        &:first-child {
          display: block;
          margin: 0 auto;
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
}
.page-wrapper {
  .page-main-header {
    background-color: $main-header-bg-color;
    height: $header-size;
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    box-shadow: $main-header-shadow;
    width: calc(100% - #{$sidebar-width});
    display: flex;
    align-items: center;
    margin-left: $sidebar-width;
    transition: $sidebar-transition;
    &.open {
      margin-left: 0;
      width: 100%;
      transition: $sidebar-transition;
    }
  }
  .page-body-wrapper {
    .page-body {
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      background-color: $page-body-bg-color;
    }
    .page-header {
      padding-top: $page-header-padding;
      padding-bottom: $page-header-padding;
      .row {
        align-items: center;
        .page-header-left {
          display: flex;
          align-items: center;
        }
        h3 {
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          font-weight: $page-title-font-weight;
          text-transform: $page-title-text-tranform;
        }
      }
      .breadcrumb {
        background-color: $transparent-color;
        padding: 0;
        margin-bottom: 0;
        margin-left: 30px;
        .breadcrumb-item {
          & + .breadcrumb-item {
            &:before {
              content: $breadcrumb-content;
            }
          }
          a {
            svg {
              width: $breadcrumb-size;
              height: $breadcrumb-size;
              vertical-align: $breadcrumb-svg-icon-align;
            }
          }
        }
      }
    }
  }
}
/*======= Page Header css ends  ======= */

/**======Main Header css Start ======**/
.offcanvas,
.offcanvas-bookmark {
  .page-wrapper {
    .page-body-wrapper {
      .page-body {
        position: relative;
        &:before {
          position: fixed;
          content: "";
          background-color: rgba(0, 0, 0, 0.5);
          height: 100%;
          width: 100%;
          z-index: 8;
          right: 0;
          left: 0;
        }
      }
    }
  }
}
.offcanvas-bookmark {
  &.page-wrapper {
    .page-body-wrapper {
      .page-body {
        .bookmark {
          ul {
            li {
              a {
                z-index: 99999;
                color: rgba($white, 0.5);
              }
              .Typeahead-menu {
                a {
                  color: $theme-body-font-color;
                }
              }
            }
          }
        }
      }
    }
  }
  &:before {
    position: fixed;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    z-index: 99;
    right: 0;
    left: 0;
  }
}
.page-main-header {
  max-width: 100vw;

  .main-header-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: $main-header-right-padding;
    margin: 0;
    svg {
      line {
        color: $main-header-right-toggle-color;
      }
    }
    .nav-left {
      i {
        margin-right: 20px;
      }
      input:focus {
        outline: 0 !important;
      }
    }
    .nav-right {
      text-align: right;
      padding-left: $main-header-right-nav-right;
      ul {
        li {
          .d-flex {
            .dotted-animation {
              position: relative;
              right: -3px;
              top: -7px;
              .animate-circle {
                position: absolute;
                top: -20px;
                right: -4px;
                height: 25px;
                width: 25px;
                z-index: 10;
                border: 5px solid $primary-color;
                border-radius: 70px;
                animation: heartbit 1s ease-out;
                animation-iteration-count: infinite;
              }
              .main-circle {
                width: 6px;
                height: 6px;
                border-radius: 30px;
                background-color: $primary-color;
                position: absolute;
                right: 6px;
                top: -10px;
              }
            }
          }
          svg {
            margin-top: $main-header-right-nav-icon-margin-top;
            width: $main-header-right-nav-icon-size;
            height: $main-header-right-nav-icon-size;
            color: $main-header-right-nav-icon-color;
          }
          .dot {
            width: 3px;
            height: 3px;
            border-radius: 30px;
            background-color: $main-header-right-nav-icon-color;
            position: absolute;
            right: 17px;
            bottom: 6px;
            animation: blink 1.5s infinite;
          }
          .dot-chat {
            right: 4px;
            bottom: -11px;
          }
        }
      }
      .Typeahead-menu {
        .ProfileCard-avatar {
          svg {
            margin-top: 0;
          }
        }
      }
      .notification {
        position: absolute;
        top: 21px;
        right: -1px;
        font-size: 9px;
        animation: blink 1.5s infinite;
        @keyframes blink {
          0% {
            opacity: 1;
          }

          70% {
            opacity: 1;
          }

          80% {
            opacity: 0;
          }

          100% {
            opacity: 0;
          }
        }
      }
      .icon-user {
        font-size: 16px;
      }
      @keyframes heartbit {
        0% {
          transform: scale(0);
          opacity: 0;
        }
        25% {
          transform: scale(0.1);
          opacity: 0.1;
        }
        50% {
          transform: scale(0.5);
          opacity: 0.3;
        }
        75% {
          transform: scale(0.8);
          opacity: 0.5;
        }
        100% {
          transform: scale(1);
          opacity: 0;
        }
      }
      > ul {
        display: flex;
        align-items: center;
        > li {
          position: relative;
          border-left: 1px solid $light-semi-gray;
          // padding: 0 20px;
          &:first-child {
            width: 100%;
            // margin: 0 auto;
            border-left: none;
            .search-form {
              .form-group {
                width: 100%;
                position: relative;
                &:focus {
                  outline-color: transparent;
                }
                input {
                  &:focus {
                    outline-color: transparent;
                  }
                }
                &:before {
                  position: absolute;
                  content: "";
                  width: 1px;
                  height: 25px;
                  background: $light-gray;
                  left: 51px;
                  top: 9px;
                  z-index: 2;
                }
                &:after {
                  position: absolute;
                  content: "\f002";
                  font-family: FontAwesome;
                  top: 11px;
                  left: 22px;
                  color: #8e8e8e;
                }
              }
            }
          }
          &:last-child {
            border-left: none;
            padding-right: 0;
          }
          &:nth-child(5) {
            border-left: none;
            border-right: 1px solid $light-semi-gray;
            padding-left: 0;
          }
          h6 {
            margin-top: 10px;
            color: $primary-color;
            ul {
              left: inherit;
              right: -10px;
              width: 130px;
              &:before,
              &:after {
                left: inherit;
                right: 10px;
              }
              li {
                display: block;
                a {
                  font-size: 14px;
                  color: $dark-color;
                  i {
                    margin-left: 10px;
                    font-size: 13px;
                    color: $dark-color;
                  }
                }
              }
            }
          }
        }
        .flag-icon {
          font-size: 16px;
        }
      }
      .notification-dropdown {
        top: 57px;
        .d-flex {
          h6.txt-success {
            svg {
              stroke: $success-color;
            }
          }
          h6.txt-danger {
            svg {
              stroke: $danger-color;
            }
          }
        }
      }
      .language-dropdown {
        width: 160px;
        text-align: left;
        top: 57px;
        li {
          padding-top: 10px;
          a {
            color: $dark-color;
            i {
              margin-right: 10px;
            }
          }
          &:first-child {
            padding-top: 0;
          }
        }
      }
      .profile-dropdown {
        right: -10px !important;
        left: inherit;
        width: 150px;
        top: 63px;
        &:before,
        &:after {
          left: inherit;
          right: 10px;
        }
        li {
          display: block;
          text-align: left;
          padding-top: 10px;
          &:nth-child(3) {
            padding-bottom: 10px;
          }
          &:nth-child(4) {
            border-top: 1px solid $light-semi-gray;
          }
          &:first-child {
            padding-top: 0;
          }
          &:hover {
            a {
              color: $primary-color;
              transition: $sidebar-transition;
              svg {
                color: $primary-color !important;
                path,
                line {
                  color: $primary-color !important;
                }
              }
            }
          }
          a {
            color: $theme-body-font-color;
            transition: $sidebar-transition;
            display: flex;
            align-items: center;
            svg {
              margin-right: 10px;
              color: $theme-body-font-color !important;
              path,
              line {
                color: $theme-body-font-color !important;
              }
            }
          }
        }
      }
    }
    li {
      display: inline-block;
      position: relative;
    }
  }
}
.onhover-dropdown {
  cursor: pointer;
  position: relative;
  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }
}
ul.notification-dropdown.onhover-show-div {
  width: 330px;
  right: -18px;
  left: initial;
  &:before,
  &:after {
    left: inherit !important;
    right: 35px !important;
  }
  li {
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid $light-color;
    text-align: left;
    h6 {
      small {
        padding-top: 3px;
        color: $dark-gray;
        font-size: 12px;
      }
    }
    .font-success {
      .line {
        color: $success-color !important;
      }
    }
    .font-danger {
      .line {
        color: $danger-color !important;
      }
    }
    span {
      svg {
        margin-top: 0 !important;
        margin-right: 10px;
        vertical-align: text-top;
      }
      .shopping-color {
        path,
        line {
          color: $primary-color;
        }
      }
      .download-color {
        path,
        line {
          color: $success-color;
        }
      }
      .alert-color {
        path,
        line {
          color: $danger-color;
        }
      }
    }
    p {
      margin-left: 30px;
    }
    + li {
      &:hover {
        background-color: $light-color;
      }
    }
  }
}
.onhover-show-div {
  top: $header-size;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;
  li {
    a {
      svg {
        margin-top: 0 !important;
        path,
        line {
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}
/**======Main Header css Ends ======**/

ul {
  .submenu {
    width: 0;
    overflow: hidden;
    list-style-type: none;
    background: $white;
    transition: width 0.5s ease-out;
    position: absolute;
    top: -8px;
    right: -12px;
    input {
      padding: 6px 12px;
      width: 200px;
    }
  }
}
.scorlled {
  .page-sidebar {
    top: 0 !important;
    height: 100vh !important;
  }
}

/** ===== Vertical Menu css Start=====**/

.page-wrapper .vertical-menu-main {
  position: fixed;
  top: $header-size;
  width: 100%;
  z-index: 9;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
}
.vertical-menu-mt {
  margin-top: 133px !important;
  .custom-card {
    .card-header {
      img {
        margin-top: -146px !important;
      }
    }
  }
}
.vertical-menu-main {
  #main-nav {
    #main-menu {
      li {
        a {
          svg {
            width: 15px;
            height: 15px;
            margin-right: 8px;
            stroke-width: 2.5;
          }
        }
      }
    }
  }
  .mega-menu {
    width: 1500px !important;
    max-width: 1500px !important;
    padding: 0;
    left: -120px !important;
    top: 65px !important;
  }
}
/** ===== Vertical Menu css Ends=====**/

/* ========sidebar icon vertical menu start=======*/

.compact-switch {
  display: none;
}
.sidebar-toggle-btn {
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}
/* ========sidebar icon vertical menu end=======*/

/*=======Mega menu css start=======*/
.mega-menu {
  perspective: inherit;
  position: static !important;
  padding-top: 5px;
  padding-left: 30px;
  .default-according {
    .card {
      .btn-link {
        font-weight: 500;
        color: gray;
      }
      .card-body {
        font-size: 12px;
      }
    }
  }
  .onhover-show-div {
    width: 1500px;
  }
  .card-body {
    padding: 20px;
  }
  .title {
    color: $theme-body-font-color;
    font-weight: 600;
    border-bottom: 1px solid $light-semi-gray;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  div {
    > div {
      a {
        margin-bottom: 0px;
        display: inline-block;
        color: $theme-body-sub-title-color;
      }
    }
  }
  .custom-nav-img {
    position: static;
    .desk {
      line-height: 25px;
      font-size: 14px;
      color: $theme-body-sub-title-color;
    }
  }
  .list-unstyled {
    div {
      a {
        padding: 8px 35px 8px 0;
        transition: $sidebar-transition;
        &:hover {
          padding: 8px 35px 8px 10px;
          transition: $sidebar-transition;
        }
      }
    }
  }
  .galleria div {
    margin-bottom: 5px;
    &:hover {
      transform: translateY(4px) scale(1.04);
    }
  }
  .galleria-list .galleria > div {
    width: 25%;
    font-size: 12px;
    float: left;
    color: $black;
    transition: ease-in 0.3s;
    > a {
      display: block;
      margin: 5px 0 10px;
      padding: 0 0 !important;
    }
    img {
      border-radius: 3px;
      max-width: 100%;
    }
    .username {
      font-size: 12px;
      color: $theme-body-font-color;
      margin-bottom: 5px;
      small {
        display: block;
        font-size: 11px;
        color: $theme-body-sub-title-color;
        margin-top: 3px;
      }
    }
  }
  .drilldown {
    overflow: hidden;
    width: 100%;
    padding: 0;
    transform: translate3d(0, 0, 0);
    a {
      width: 100%;
      &:hover {
        background-color: transparent;
        color: $primary-color;
      }
    }
  }
  .drilldown-sub {
    display: none;
  }
  .drilldown-back {
    font-weight: bold;
  }
}
/*=======Mega menu css end=======*/

/**=====================
    05. Header CSS Ends
==========================**/
