.customNav {
}
.customNavItem:hover {
  background-color: transparent !important;
  cursor: pointer !important;
}

.absolute-total {
  position: absolute;
  right: 3%;
  top: 1%;
}
.absolute-string {
  position: absolute;
  right: 3%;
  top: 0%;
  color: gray;
  font-size: 12px;
}

.custominput {
  font-size: 12px !important;
}

.product-scroll-custom {
  max-height: 620px;
  overflow-y: auto;
  & {
    -webkit-transition: all 3s;
    transition: all 3s;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #e8ebf2;
  }
  &::-webkit-scrollbar {
    width: 6px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
