.single-card {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  padding-bottom: 15px;
  margin: 0;
}
.single-card:hover {
  opacity: 0.7;
  cursor: pointer;
}
